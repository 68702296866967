/* eslint-disable jsx-a11y/anchor-is-valid */
import { graphql, useStaticQuery } from 'gatsby'
import * as JsSearch from 'js-search'
import { stemmer } from 'porter-stemmer'
import React, { useEffect, useState } from 'react'
import { Search as SearchIcon } from 'react-feather'
import Index from '../components/Index'
import Layout from '../components/Layout'
import { useUrlState } from '../utils/hooks'

/**
 * Creates a data structure for searching multiple indices.
 * This context can be used to define searchable attributes and has the necessary methods to perform searching on the index.
 * @param {Object[]} indices
 * @returns {Object} context
 */
const initializeSearchContext = indices => {
  // construct a search object "context"
  const searchObj = {}
  indices.forEach(index => {
    // define JsSearch with the field which is unique on each data set - id
    const Search = new JsSearch.Search('id')
    // set the tokenizer
    // here we use a stemming tokenizer
    // Stemming is the process of reducing search tokens to their root (or "stem")
    // For example "search", "searching" and "searched" can all be reduced to the stem "search".
    Search.tokenizer = new JsSearch.StemmingTokenizer(
      stemmer,
      new JsSearch.SimpleTokenizer()
    )
    // we want to use toLowerCase on each query and index value to maximize results
    Search.sanitizer = new JsSearch.LowerCaseSanitizer()
    Search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    searchObj[index.slug] = {}
    // place search context into the object
    searchObj[index.slug].Search = Search
    // define a way to initialize the search by adding all searchable fields and data (documents)
    searchObj[index.slug].initializeSearch = function initializeSearch() {
      index.searchableFields.forEach(field => {
        Search.addIndex(field)
      })
      Search.addDocuments(index.items)
    }
    // add index context
    searchObj[index.slug].index = index

    // finally, call initializeSearch on each index
    searchObj[index.slug].initializeSearch()
  })
  return searchObj
}

/**
 * Uses the indices context to construct the initial state.
 * In this case, I have defined the initial state to be the data that was passed into the index (all the data).
 * If you want there to be no data when passed in then modify this function to make an index have an empty array as its results.
 * @param {Object[]} indices
 * @param {string} currentIndex current active index slug
 * @returns {Object[]} search state
 */
const getInitialSearchResults = (indices, currentIndex = null) =>
  currentIndex
    ? indices
        .map(index => ({ ...index, results: index.items }))
        .filter(idx => idx.slug === currentIndex)
    : indices.map(index => ({ ...index, results: index.items }))

const IndexTab = ({ index, isActive, onClick: onTabClick, onAllClick }) =>
  index.slug === 'all' ? (
    <li className="is-marginless">
      <a
        role="button"
        tabIndex="-1"
        onClick={onAllClick}
        onKeyDown={() => null}
        aria-label={index.name}
        style={{
          borderBottomColor: isActive ? '#B11A1B' : '#dbdbdb',
          color: isActive ? '#B11A1B' : '#4a4a4a',
        }}
      >
        <span>{index.name}</span>
      </a>
    </li>
  ) : (
    <li
      key={index.slug}
      className={isActive ? 'is-active is-marginless' : 'is-marginless'}
    >
      <a
        role="button"
        tabIndex="-1"
        onClick={e => onTabClick(e, index)}
        onKeyDown={() => null}
        aria-label={index.name}
        style={{
          borderBottomColor: isActive ? '#B11A1B' : '#dbdbdb',
          color: isActive ? '#B11A1B' : '#4a4a4a',
        }}
      >
        <span>{index.name}</span>
      </a>
    </li>
  )

export const SearchTemplate = ({ indices = [] }) => {
  const [searchContext, setSearchContext] = useState({})
  const [searchResults, setSearchResults] = useState(
    getInitialSearchResults(indices)
  )
  const [searchQuery, setSearchQuery, clearSearchQuery] = useUrlState(
    '',
    'query'
  )
  const [currentIndexSlug, setCurrentIndexSlug] = useUrlState('all', 'index')
  const tabIndices = Array.from(indices)
  tabIndices.unshift({ name: 'All', slug: 'all' })

  /**
   * Performs a search through either all indices or the specific one selected and returns an array of indices with search results on each
   * @param {string} query Search query
   * @param {Object=} context Search context
   * @param {string=} currentIndex current active index
   * @returns {Object[]} search results
   */
  const search = (query, context = searchContext, currentIndex = null) => {
    const results = []
    Object.keys(context).forEach(key => {
      if (currentIndexSlug !== 'all' && currentIndexSlug === key) {
        results.push({
          ...context[key].index,
          results: context[key].Search.search(query),
        })
      } else {
        results.push({
          ...context[key].index,
          results: context[key].Search.search(query),
        })
      }
    })

    return currentIndex
      ? results.filter(idx => idx.slug === currentIndex)
      : results
  }

  useEffect(() => {
    /* Contains search methods and index context */
    const context = initializeSearchContext(indices)
    // set the search context state
    setSearchContext(context)

    // if query exists perform a search on mount
    // if no query exists, populate search results with initial search state
    if (searchQuery) {
      // query exists
      // find out if we are searching in all indices or just one
      if (currentIndexSlug !== 'all') {
        // filter search to specific index
        setSearchResults(search(searchQuery, context, currentIndexSlug))
      } else {
        // search all indices
        setSearchResults(search(searchQuery, context))
      }
    } else {
      // filter search to specific index
      if (currentIndexSlug !== 'all') {
        setSearchResults(getInitialSearchResults(indices, currentIndexSlug))
      } else {
        // no filter search
        setSearchResults(getInitialSearchResults(indices))
      }
    }
  }, [])

  /**
   * Handles when the user types into the search bar
   */
  const onSearchChange = e => {
    // e.target.value is the value that the user inputs into the search bar
    const context = initializeSearchContext(indices)

    // update the search context
    setSearchContext(context)
    setSearchQuery(e.target.value)

    // clear search when there is no input
    if (e.target.value.length === 0) {
      if (currentIndexSlug !== 'all') {
        setSearchResults(getInitialSearchResults(indices, currentIndexSlug))
      } else {
        setSearchResults(getInitialSearchResults(indices))
      }
      return
    }

    // if we get here then we have input... call search function on input
    if (currentIndexSlug !== 'all') {
      setSearchResults(search(e.target.value, context, currentIndexSlug))
    } else {
      setSearchResults(search(e.target.value, context))
    }
  }

  /**
   * Clears search from local state and url
   */
  const onClearSearch = () => {
    setSearchQuery('')
    if (currentIndexSlug !== 'all') {
      setSearchResults(getInitialSearchResults(indices, currentIndexSlug))
    } else {
      setSearchResults(getInitialSearchResults(indices))
    }
    clearSearchQuery()
  }

  const onTabClick = (e, index) => {
    e.preventDefault()
    const context = initializeSearchContext(indices)
    setSearchContext(context)
    setCurrentIndexSlug(index.slug)
    if (searchQuery.length) {
      setSearchResults(search(searchQuery, context, index.slug))
    } else {
      setSearchResults(getInitialSearchResults(indices, index.slug))
    }
  }

  const onAllTabClick = e => {
    e.preventDefault()
    const context = initializeSearchContext(indices)
    setSearchContext(context)
    setCurrentIndexSlug('all')
    if (searchQuery.length) {
      setSearchResults(search(searchQuery, context))
    } else {
      setSearchResults(getInitialSearchResults(indices))
    }
  }

  return (
    <div role="main">
      <section className="section">
        <div
          className="container content"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <div className="tabs is-fullwidth">
            <ul className="is-marginless">
              {tabIndices.map(index => (
                <IndexTab
                  key={index.slug}
                  index={index}
                  isActive={currentIndexSlug === index.slug}
                  onClick={onTabClick}
                  onAllClick={onAllTabClick}
                />
              ))}
            </ul>
          </div>
          <div className="control has-icons-left has-icons-right">
            <input
              className="input"
              type="text"
              placeholder="Search..."
              aria-label="Search bar"
              value={searchQuery}
              onChange={onSearchChange}
            />
            <span className="icon is-medium is-left">
              <SearchIcon />
            </span>
            {searchQuery.length > 0 && (
              <span className="icon is-small is-right">
                <button
                  type="button"
                  tabIndex="-1"
                  className="delete is-small"
                  onClick={onClearSearch}
                />
              </span>
            )}
          </div>
          {searchResults.map(index => (
            <Index key={index.slug} index={index} />
          ))}
        </div>
      </section>
    </div>
  )
}

const SearchPage = ({ pageContext: { indices } }) => {
  const { featuredImage } = useStaticQuery(graphql`
    {
      featuredImage: wordpressWpMedia(slug: { eq: "search-media" }) {
        localFile {
          childImageSharp {
            fluid(quality: 100, maxHeight: 600) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  `)

  return (
    <Layout
      header={{
        title: 'Search',
        subtitle: 'Find just what you need by searching the entire site',
      }}
      seo={{
        pageTitle: 'Search',
      }}
      featuredMedia={featuredImage}
      lightText
    >
      <SearchTemplate indices={indices} />
    </Layout>
  )
}

export default SearchPage
