import chunk from 'lodash/chunk'
import React, { useEffect, useState } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import SearchHit from './SearchHit'

/**
 * @typedef {Object} SearchResult
 * @property {String} content
 * @property {String} description
 * @property {String} id
 * @property {String} path
 * @property {String} slug
 * @property {String=} statement
 * @property {String} title
 * @property {String} type
 */
/**
 * @typedef {Object} Index
 * @property {Number} hitsPerPage
 * @property {Array<SearchResult>} items
 * @property {String} name
 * @property {Number} numberOfColumns
 * @property {Array<SearchResult>}results
 * @property {Array<String>} searchableFields
 */
/**
 * @param {Index} index
 */
const Index = ({ index }) => {
  /* Keeps track of what page the user is on */
  const [pageNumber, setPageNumber] = useState(1)
  /* Will be an array of the current chunked data set at the page number specified above */
  const [paginatedResults, setPaginatedResults] = useState([])
  /* Holds the amount of pages of results for a given index */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setPageNumber(1)
    const chunkedResults = chunk(index.results, index.hitsPerPage)
    setTotalPages(chunkedResults.length)
    setPaginatedResults(chunkedResults[pageNumber - 1])
  }, [index.results])

  useEffect(() => {
    const chunkedResults = chunk(index.results, index.hitsPerPage)
    setTotalPages(chunkedResults.length)
    setPaginatedResults(chunkedResults[pageNumber - 1])
  }, [pageNumber])

  return (
    <div className="search-index" key={index.slug}>
      <div className="search-index-header mb-4">
        <h4 className="is-marginless is-vcentered">{index.name}</h4>
        <div className="horizontal-list is-vcentered fd-r align-items-center">
          <span>
            {totalPages > 0 ? `${pageNumber} /` : ''} {totalPages}
          </span>
          <button
            type="button"
            tabIndex="-1"
            rel="prev"
            className="button pagination-button has-border"
            aria-label="Previous page"
            onClick={() => setPageNumber(prevPageNumber => prevPageNumber - 1)}
            disabled={pageNumber === 1}
          >
            <ArrowLeft size={18} />
          </button>
          <button
            type="button"
            tabIndex="-1"
            rel="next"
            aria-label="Next page"
            className="button pagination-button has-border"
            onClick={() => setPageNumber(prevPageNumber => prevPageNumber + 1)}
            disabled={pageNumber === totalPages || totalPages === 0}
          >
            <ArrowRight size={18} />
          </button>
        </div>
      </div>
      {index.results && (
        <p className="mb-4">
          <strong>{index.results.length}</strong> result
          {index.results.length > 1 || index.results.length === 0 ? 's' : ''}
        </p>
      )}
      {paginatedResults && paginatedResults.length ? (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
          {paginatedResults.map(hit => (
            <SearchHit key={hit.id} index={index} hit={hit} />
          ))}
        </div>
      ) : (
        <h6>
          Sorry, no {index.name.toLowerCase()} matched your search criteria
        </h6>
      )}
    </div>
  )
}

export default React.memo(Index)
