import { Link } from 'gatsby'
import React from 'react'
import { Archive, File, Tag } from 'react-feather'
import { decodeHtml, formatBytes, trunc } from '../utils/helpers'
import Post from './Post'

const SHORT_DESCRIPTION_LENGTH = 210

function shortenDescription(description) {
  return trunc(description, SHORT_DESCRIPTION_LENGTH)
}

const Hit = ({ index, hit }) => {
  switch (index.slug) {
    case 'pages':
      // eslint-disable-next-line no-case-declarations
      const breadcrumb = hit.path.split('/')
      breadcrumb.pop()
      breadcrumb.shift()

      return (
        <Link to={hit.path}>
          <div className="card--with-border search-result">
            <div className="card-content">
              <div>
                <h6 className="is-marginless semibold">
                  {hit.title || hit.name}
                </h6>
              </div>
              <ul className="search-breadcrumb">
                {breadcrumb.map(path => (
                  <React.Fragment key={path}>
                    <li key={path}>
                      <span>{path}</span>
                    </li>
                  </React.Fragment>
                ))}
              </ul>
              <span>{shortenDescription(hit.description)}</span>
            </div>
          </div>
        </Link>
      )
    case 'posts':
      return (
        <Post
          post={hit}
          author={hit.author}
          date={hit.date}
          excerpt={hit.excerpt}
          slug={hit.slug}
          featured_media={hit.featured_media}
        />
      )
    case 'media':
      return (
        <a href={hit.slug}>
          <div className="card--with-border search-result">
            <div className="card-content">
              <div className="columns">
                <div className="column is-narrow is-hcentered">
                  <File className="has-text-dark" size={56} />
                </div>
                <div className="column vertical-list-small is-vcentered">
                  <div>
                    <h6 className="is-marginless semibold">
                      {decodeHtml(hit.title)}
                    </h6>
                  </div>
                  <div>
                    <span className="tag is-dark is-uppercase semibold">
                      {hit.type}
                    </span>
                  </div>
                  <span>{formatBytes(hit.localFile.size)}</span>
                </div>
              </div>
            </div>
          </div>
        </a>
      )
    case 'categories':
      return (
        <Link to={`/${hit.slug}/`}>
          <div className="card--with-border search-result">
            <div className="card-content">
              <div className="is-flex align-items-center">
                <Archive className="has-text-dark" size={56} />
                <span>{hit.name}</span>
              </div>
              <span>{hit.description}</span>
            </div>
          </div>
        </Link>
      )
    case 'tags':
      return (
        <Link to={`/${hit.slug}/`}>
          <div className="card--with-border search-result">
            <div className="card-content">
              <div className="is-flex align-items-center">
                <Tag className="has-text-dark" size={56} />
                <span>{hit.name}</span>
              </div>
              <span>{hit.description}</span>
            </div>
          </div>
        </Link>
      )
    default:
      return (
        <Link to={`/${hit.slug}/`}>
          <div className="card--with-border search-result">
            <div className="card-content">
              <div>
                <span>{hit.title}</span>
              </div>
              <span>{hit.description}</span>
            </div>
          </div>
        </Link>
      )
  }
}

export default Hit
