import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { decodeHtml } from '../utils/helpers'

const Post = ({
  post,
  author,
  title = 'Missing Title',
  excerpt = '',
  slug,
  dateTime = '',
  date = '',
  featured_media: media,
  meta,
  displayExcerpt = true,
}) => {
  const url = `/blog/${String(slug).trim()}/`
  let authorProfilePic
  if ('avatar_urls' in author) {
    authorProfilePic = author.avatar_urls.wordpress_96
  }

  let readingTime
  if (meta && 'readingTime' in meta) {
    readingTime = meta.readingTime.text
  }

  return (
    <div className="flex flex-col rounded-lg shadow-xl overflow-hidden">
      {media && media.localFile && (
        <div className="flex-shrink-0">
          <Link to={url} className="block">
            <div className="relative">
              <Img
                fluid={media.localFile.childImageSharp.fluid}
                className="h-auto w-full object-cover"
                alt=""
              />
            </div>
          </Link>
        </div>
      )}
      <div className="flex-1 bg-white p-7 flex flex-col justify-between">
        <div className="flex-1">
          <Link to={url} className="block">
            <h3 className="text-xl leading-7 font-semibold text-gray-900">
              {decodeHtml(post.title || title)}
            </h3>
            {excerpt && (
              <p
                className="mt-3 text-base leading-6 text-gray-700"
                dangerouslySetInnerHTML={{ __html: excerpt }}
              />
            )}
          </Link>
        </div>
        <div className="mt-4 flex items-center">
          {authorProfilePic && (
            <div className="flex-shrink-0">
              <img
                className="h-10 w-10 rounded-full mr-4"
                src={authorProfilePic}
                alt=""
              />
            </div>
          )}
          <div>
            <p className="leading-5 text-md font-medium text-gray-900 m-0">
              {author.name}
            </p>
            <div className="flex text-md leading-5 text-gray-700">
              <time dateTime={dateTime}>{date}</time>
              {meta && (
                <>
                  <span className="mx-1">·</span>
                  <span>{readingTime}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Post
